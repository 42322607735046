<template>
  <v-container>
    <v-row justify="center">
      <h1 class="headline font-weight-bold mb-3" justify="center">
        Оформление нового заказа
      </h1>
    </v-row>
    <v-row justify="center">
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th class="subtitle-2 text-center">Услуга</th>
              <th class="text-left" colspan="2">Стоимость</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h1
                  class="display-1 font-weight-bold indigo--text mb-4"
                  id="maincolor"
                >
                  {{ usluga }}
                </h1>
              </td>
              <td>
                <h2 class="font-weight-bold mb-4">{{ cena }}</h2>
              </td>
              <td>
                <h4 class="font-weight-bold mb-4">{{ valuta }}</h4>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step step="1" :complete="e1 > 1">
          Данные клиента
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step
          step="2"
          :editable="e1 > 1 && e1 > 3"
          :complete="e1 > 2"
        >
          Оформление письма
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="3" :editable="e1 > 2">
          Оплата заказа
        </v-stepper-step>
      </v-stepper-header>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row class="text-center">
              <v-col cols="12">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <VuePhoneNumberInput
                        @update="phoneLoad"
                        v-model="phoneNumber"
                        :fetch-country="true"
                        :no-example="true"
                        type="tel"
                        id="phone"
                        name="phone"
                        :translations="transltations"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="E-mail"
                        v-model="email"
                        :rules="emailRules"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-file-input
                        v-model="upload"
                        id="fileselectall"
                        truncate-length="100"
                        label="Файл с Application Form (анкетой)"
                        accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        show-size
                        outlined
                        required
                        :rules="fileRules"
                        prepend-icon="mdi-attachment"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
                <v-col cols="12" sm="12">
                  <v-switch
                    v-model="nuznhoIskluchat"
                    :label="`Отправить по всем компаниям`"
                    @click="clearAutoComplete"
                  ></v-switch>
                  <template v-if="nuznhoIskluchat === false">
                    <v-row justify="center">
                      <v-simple-table>
                        <template>
                          <thead>
                            <tr>
                              <th class="subtitle-2 text-center">
                                Компаний получателей
                              </th>
                              <th class="subtitle-2 text-center">Исключено</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h1
                                  class="
                                    text-center
                                    font-weight-bold
                                    indigo--text
                                    mb-4
                                  "
                                  id="maincolor"
                                >
                                  {{ afterMinusCompany }}
                                </h1>
                              </td>
                              <td>
                                <h1
                                  class="
                                    display-1
                                    font-weight-bold
                                    indigo--text
                                    mb-4
                                  "
                                  id="maincolor"
                                >
                                  {{ komp.length }}
                                </h1>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-row>
                    <v-autocomplete
                      v-model="komp"
                      :items="states"
                      label="Компании исключения"
                      placeholder="Введите для поиска или выберете из списка"
                      multiple
                      chips
                      deletable-chips
                      dense
                      hint="куда письмо НЕ будет отправлено"
                      persistent-hint
                    ></v-autocomplete> </template
                ></v-col>
                <v-btn color="primary" @click="secondEtap" :disabled="!valid">
                  далее
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" class="display-1 font-weight-bold mb-6">
            </v-row>
          </v-stepper-content>
        </v-stepper-items>

        <v-stepper-items>
          <v-stepper-content step="2">
            <div>
              <v-alert type="success" v-model="alert" icon="mdi-emoticon"
                >Для каждого клиента бесплатно составляется сопроводительное
                письмо на основании анкеты.
              </v-alert>
              <v-alert type="info" dismissible icon="mdi-pen"
                >Для повышения эффективности рекомендуем Вам самостоятельно
                добавить информацию на английском языке <br />– опыте работы в
                специфических районах или условиях <br />– навыках работы с
                определенным оборудованием <br />– наличии дополнительных
                документов >наличии иностранных виз или подфлажных документов
              </v-alert>
            </div>

            <template>
              <v-col cols="12" sm="12">
                <v-text-field
                  id="subject"
                  v-model="subject"
                  counter="80"
                  maxlength="80"
                  placeholder="eg. Master - Oil/Chem Tanker - Good English & C1/D Visa"
                  hint="position + for deck enter type of fleet, for motor enter type of ME on your last vessels + English Level (*visa if you have)"
                  label="Тема письма"
                ></v-text-field>
                <editor
                  id="coverLetterText"
                  v-model="coverLetterText"
                  name="qwerty"
                  ref="ref"
                  :init="{
                    forced_root_block: '',
                    remove_linebreaks: true,
                    force_br_newlines: true,
                    force_p_newlines: false,
                    branding: false,
                    height: 300,
                    menubar: 'tools',
                    plugins: [
                      'code advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code wordcount',
                    ],
                    contextmenu: 'bold italic',
                    selector: 'textarea',
                    toolbar:
                      'code undo redo | bold italic link | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat |',
                  }"
                />
              </v-col>

              <div>
                <v-row justify="center">
                  <v-dialog v-model="dialogtoPay" persistent max-width="390">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="ma-6" fab small @click="e1 = 1">
                        <v-icon dark> mdi-keyboard-backspace </v-icon>
                      </v-btn>
                      <v-btn
                        color="success"
                        class="mt-6"
                        v-bind="attrs"
                        v-on="on"
                      >
                        оформить заявку
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="headline text-center"
                        >ВНИМАНИЕ</v-card-title
                      >
                      <v-card-text>
                        Вы уверены в корретности заполнения всех данных? После
                        подтверждения возврат к предыдущему шагу будет
                        невозможен!
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text @click="dialogtoPay = false">
                          ХОЧУ ПЕРЕПРОВЕРИТЬ
                        </v-btn>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="submit() + (loader = 'loading')"
                          :loading="loading"
                          :disbled="loading"
                          >ДА
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>
            </template></v-stepper-content
          >
        </v-stepper-items>
      </v-form>
      <v-stepper-items>
        <v-stepper-content step="3"
          ><PayOnline :id="id" :nomerZakaza="nomerZakaza" :userID="userID" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import PayOnline from "@/views/PayOnline.vue";
import axios from "axios";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    VuePhoneNumberInput: VuePhoneNumberInput,
    editor: Editor,
    PayOnline,
  },
  name: "HelloWorld",
  data: () => ({
    id: "",
    dialogtoPay: false,
    nomerZakaza: 0,
    userID: "",
    checkbox: false,
    states: [],
    upload: null,
    valid: true,
    loader: null,
    loading: false,
    name: "",
    subject: "",
    coverLetterText: "",
    kolvokompaniy: 0,
    nuznhoIskluchat: true,
    komp: [],
    e1: 1,
    steps: 2,
    usluga: "",
    email: "",
    phoneNumber: "",
    resultsPhone: "",
    fileRules: [(v) => !!v || "выберете файл с анкетой"],
    emailRules: [
      (v) => !!v || "введите адрес элетронной почты",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "введите корректный адрес электронной почты",
    ],
    transltations: {
      countrySelectorLabel: "код страны",
      countrySelectorError: "проверьте правильность ввода",
      phoneNumberLabel: "номер телефна",
      example: "например:",
    },
  }),
  computed: {
    minusbadSymbolLetter: function () {
      // eslint-disable-next-line prettier/prettier
      return this.coverLetterText
    },
    minusbadSymbolSubject: function () {
      // eslint-disable-next-line prettier/prettier
      return this.subject
    },
    afterMinusCompany: function () {
      return this.kolvokompaniy - this.komp.length;
    },
    linkMinus: function () {
      let usluga = this.usluga;
      let linkID = "";
      if (usluga.endsWith("по расположению Украина")) {
        linkID = 1;
        return linkID;
      }
      if (usluga.endsWith("по расположению Европа")) {
        linkID = 2;
        return linkID;
      }
      if (usluga.endsWith("по расположению Европа плюс")) {
        linkID = 3;
        return linkID;
      }
      if (usluga.endsWith("по расположению Россия")) {
        linkID = 4;
        return linkID;
      }
      if (usluga.endsWith("по расположению Ближний Восток")) {
        linkID = 5;
        return linkID;
      }
      if (usluga.endsWith("по расположению Мир")) {
        linkID = 6;
        return linkID;
      }
      if (usluga.endsWith("по типу флота Сухогрузы")) {
        linkID = 7;
        return linkID;
      }
      if (usluga.endsWith("по типу флота Танкера")) {
        linkID = 8;
        return linkID;
      }
      if (usluga.endsWith("по типу флота Газовозы")) {
        linkID = 9;
        return linkID;
      }
      if (usluga.endsWith("по типу флота Оффшор")) {
        linkID = 10;
        return linkID;
      }
      if (usluga.endsWith("по типу флота Пассажирский")) {
        linkID = 11;
        return linkID;
      }
      if (usluga.endsWith("по типу флота Яхты")) {
        linkID = 12;
        return linkID;
      }
      if (usluga.endsWith("по типу флота Буровые платформы")) {
        linkID = 13;
        return linkID;
      }
      return linkID;
    },
  },
  beforeMount() {
    axios
      .get(`${process.env.VUE_APP_API_URL}/konas/${this.linkMinus}`)
      .then((response) => {
        this.kolvokompaniy = response.data.shift();
        let arr = response.data;
        arr.splice(0, 1); // начиная с позиции 1, удалить 1 элемент
        this.states = arr;
      })
      .catch((error) => {
        alert(error);
      });
  },
  created() {
    this.parseUrl();
  },
  methods: {
    clearAutoComplete() {
      this.komp = [];
    },
    phoneLoad(payload) {
      this.resultsPhone = payload.e164;
    },
    submit() {
      const blob = this.upload;
      const formData = new FormData();
      formData.append("upload", blob);
      formData.append("email", this.email);
      formData.append("phonenumber", this.resultsPhone);
      formData.append("subject", this.minusbadSymbolSubject);
      formData.append("coverLetterText", this.minusbadSymbolLetter);
      formData.append("nuznhoIskluchat", this.nuznhoIskluchat);

      const product_id_list = this.komp;
      product_id_list.forEach((item) => {
        formData.append("spisokKompaniyIskuchat[]", item);
      });
      formData.append("usluga", this.usluga);
      formData.append("cena", this.cena);
      formData.append("valuta", this.valuta);
      axios
        .post(`${process.env.VUE_APP_API_URL}/saveNewforma`, formData)
        .then((res) => {
          if (res.data.status === "ok") {
            this.nomerZakaza = res.data.nomerZakaza;
            this.userID = res.data.userID;
            this.id = res.data.payForm;
            this.dialogtoPay = false;
            this.e1 = 3;
            // this.clearForm();
            // this.snackbar = true;
            // this.dialog = false;
            // document.location.reload();
          }
        });
      // axios
      //   .post(`${process.env.VUE_APP_API_URL}/saveNewforma`, {
      //     email: this.email,
      //     phonenumber: this.resultsPhone,
      //     subject: this.clientsubject,
      //     coverLetterText: this.clientcoverLetterText,
      //     nuznhoIskluchat: this.checkbox,
      //     spisokKompaniyIskuchat: this.kompanii,
      //     usluga: this.usluga,
      //     cena: this.cena,
      //     valuta: this.valuta,
      //   })

      //   .then((response) => {
      //     if (response.data === "success") {
      //       // eslint-disable-next-line no-return-assign
      //     }
      //     // console.log(response);
      //     // this.response = response.data
      //     this.success = "Data saved successfully";
      //     this.response = JSON.stringify(response, null, 2);
      //   })
      //   .catch((error) => {
      //     this.response = `Error: ${error.response.status}`;
      //   });
      // console.log(
      //   this.clientname +
      //     this.clientsubject +
      //     this.clientsurname +
      //     this.clientcoverLetterText
      // )
    },
    secondEtap() {
      if (this.$refs.form.validate()) {
        // Native form submission is not yet supported
        this.e1 = 2;
      }
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    parseUrl() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.usluga = urlParams.get("u") || "Экспресс по расположению Украина";
      this.cena = urlParams.get("c") || 300;
      this.valuta = urlParams.get("v") || "грн";
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 60000);

      this.loader = null;
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>
<style>
@media only screen and (max-width: 960px) {
  .v-stepper__header .v-divider {
    display: none;
  }
  .v-stepper__header {
    height: auto !important;
    display: flex !important;
  }
  .v-stepper__label {
    display: flex !important;
    margin-left: 19px !important;
    text-align: center !important;
  }
}
@media only screen and (min-width: 961px) {
  .v-stepper__header {
    height: auto !important;
    display: flex !important;
  }
}
.country-selector.has-value .country-selector__input,
.input-tel__input {
  height: 60px !important;
}
.v-stepper__content {
  padding: 0px !important;
}

div.v-input__slot > #fileselectall {
  width: 80%;
}
.tox .tox-editor-header {
  z-index: 0 !important;
}
.tox .tox-notification--warning {
  display: none !important;
}
</style>
