<template>
  <v-app>
    <v-main class="d-flex align-center">
      <router-view />
    </v-main>
    <!-- <v-footer app color="primary" dark>
      <div class="d-flex align-center">
        <a
          href="https://marinehelper.com/?utm_source=form&utm_medium=zakaz&utm_campaign=new"
          target="_blank"
        >
          <v-img
            alt="Vuetify Logo"
            class="align-center mr-2"
            contain
            :src="require('@/assets/logo.svg')"
            transition="scale-transition"
            width="70"
          />
        </a>
      </div>

      <v-spacer></v-spacer>
    </v-footer> -->
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
