<template>
  <div class="payonline">
    <v-row justify="center">
      <h1 class="pt-10">Оплата заказа</h1>
    </v-row>
    <v-col cols="12" class="pt-5">
      <div>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="390">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" large>
                СООБЩИТЬ ОБ УСПЕШНОЙ ОПЛАТЕ
                <v-icon>mdi-check-all</v-icon>
              </v-btn>
            </template>
            <v-form v-model="isFormValid" ref="myForm">
              <v-card>
                <v-col align="right" cols="12" sm="12">
                  <v-btn color="grey" text @click="dialog = false">
                    <v-icon> mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <v-card-title class="headline text-center"
                  >Подтверждение оплаты</v-card-title
                >
                <v-col cols="12" sm="12">
                  <v-select
                    :rules="rules.required"
                    v-model="sposoboplati"
                    :items="items"
                    label="Выберете способ оплаты"
                    outlined
                  ></v-select>
                  <v-text-field
                    :rules="rules.required"
                    id="detalioplati"
                    v-model="detalioplati"
                    counter="80"
                    maxlength="80"
                    placeholder="например 03.06.2025 18-40"
                    hint="укажите дату и время оплаты"
                    label="Укажите время оплаты"
                  ></v-text-field>
                </v-col>
                <v-card-text>
                  Рассылка будет запущена в рабочее время в первой половине дня
                  после проверки оплаты
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="ma-1"
                    color="success"
                    text
                    :loading="loading"
                    :disabled="!isFormValid"
                    @click="onSubmit() + (loader = 'loading')"
                  >
                    Отправить на проверку
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-row>
      </div>
    </v-col>
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <h2 class="text-center pa-1">1. Online картой Visa / Mastercard</h2>
        <div class="text-center">
          <v-progress-circular
            v-if="!id"
            :width="5"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <span v-html="id" align="center"></span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <h2 class="text-center pt-1">2. пополнением ПРИВАТ карты</h2>
        <h1 align="center">4149 4393 1909 7238</h1>
        <p class="d-flex flex-column mt-3" align="center">
          получатель - Олейник Максим воспользовавшись приложением или сайтом
          Приват24 через терминалы самообслуживания Приват
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <h2 class="text-center pt-1">3. пополнением MONOBANK карты</h2>
        <h1 align="center">5375 4114 0448 7600</h1>
        <p class="d-flex flex-column mt-3" align="center">
          с помощью платёжных терминалов iBox или через приложение MonoBank
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <h2 class="text-center pt-1">
          4. Через отделение любого банка в Украине
        </h2>
        <p class="d-flex flex-column mt-3" align="center">
          Назначение платежа: обработка резюме по заявке
          {{ nomerZakaza }} Реквизиты: UA163287040000026008054202768
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from "@/router";

const url = `${process.env.VUE_APP_API_URL}/oplataOK`;

import axios from "axios";
export default {
  data() {
    return {
      isFormValid: false,
      rules: {
        required: [(value) => !!value || "Пожалуйста, заполните поле."],
      },
      detalioplati: "",
      sposoboplati: "",
      loader: null,
      loading: false,
      dialog: false,
      items: [
        "Online картой Visa / Mastercard",
        "пополнением карты ПРИВАТ",
        "пополнением карты МОНО",
        "через отделения банков Украины",
      ],
    };
  },
  name: "PayOnline",
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this[l] = false), 10000);

      this.loader = null;
    },
  },
  methods: {
    clearForm() {
      this.$refs.forma.reset();
    },
    onSubmit() {
      const dataForProverkaOplata = {
        nomerZakaza: this.nomerZakaza,
        detalioplati: this.detalioplati,
        sposoboplati: this.sposoboplati,
        userID: this.userID,
      };
      axios.post(url, dataForProverkaOplata).then((res) => {
        if (res.data.status === "OK") {
          this.loading = false;
          router.push({
            name: "Success",
            params: {
              nomerZakaza: this.nomerZakaza,
            },
          });
        }
      });
    },
  },
  props: {
    id: {
      type: String,
    },
    userID: {
      type: String,
    },
    nomerZakaza: {
      type: Number,
    },
  },
};
</script>
